<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="filter_province">Filter By Provinces</label>
            <v-select
              id="filter_province"
              v-model="selected.filter_province"
              :options="list.filter_provinces"
              label="province_name"
              :disabled="state.busy"
              placeholder="-- Please Select Province --"
            >
              <template #option="{ province_name }">
                <span>{{ province_name }}</span>
              </template>
              <template #no-options="">
                No Available Provinces
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="filter_municipality">Filter by Municipality</label>
            <v-select
              id="filter_municipality"
              v-model="selected.filter_municipality"
              :options="list.filter_municipalities"
              label="municipality_name"
              placeholder="-- Please Select Municipality --"
            >
              <template #option="{ municipality_name }">
                <strong>{{ municipality_name }}</strong>
              </template>
              <template #no-options="">
                No Available Municipalities
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(lga)="data">
          <div class="text-nowrap">
            {{ `${data.item.submitted_by?.first_name} ${data.item.submitted_by?.last_name}` }}
          </div>
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AgentInvalidPendingDataService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AgentVisitRequests',

  middleware: ['auth', 'agent'],

  mixins: [formatter],

  metaInfo () {
    return {
      title: 'Visit Requests'
    }
  },

  data () {
    return {
      state: {
        busy: false
      },
      filter: {
        filter_province: 'all',
        filter_municipality: 'all'
      },
      list: {
        filter_provinces: [],
        filter_municipalities: []
      },
      selected: {
        filter_province: null,
        filter_municipality: null
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'status', sortable: true },
          { key: 'first_name', label: 'first name' },
          { key: 'last_name', label: 'last name' },
          { key: 'mobile_number', label: 'mobile number' },
          { key: 'province.province_name', label: 'province' },
          { key: 'municipality.municipality_name', label: 'municipality' },
          { key: 'barangay.barangay_name', label: 'barangay' },
          { key: 'lga', label: 'submmited by' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      }
    }
  },

  watch: {
    'selected.filter_province' (value) {
      this.filter.filter_province = value?.id || 'all'
      this.getMunicipalities(value?.id || null)
      if (value === null) {
        this.selected.filter_municipality = null
      }
      this.onFilterChanged()
    },

    'selected.filter_municipality' (value) {
      this.filter.filter_municipality = value?.id || 'all'
      this.onFilterChanged()
    }
  },

  mounted () {
    core.index()
    this.getProvinces()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await AgentInvalidPendingDataService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_province: this.filter.filter_province,
            filter_municipality: this.filter.filter_municipality,
            filter_text: ctx.filter
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getProvinces () {
      await SharedListService.getProvinces().then(({ data }) => {
        this.list.filter_provinces = data
      })
    },

    async getMunicipalities (province) {
      this.selected.municipality = null
      if (province) {
        await SharedListService.getMunicipalities(`province_id=${province}`).then(({ data }) => {
          this.list.filter_municipalities = data
        })
      } else {
        this.list.filter_municipalities = []
      }
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    }
  }
}
</script>
